// import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Footer.scss";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "../../../Assets/footer/icons8-twitter-40.svg";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import ZeeroFullLogo from "../../../Assets/SVG/logo_main/zeero_full_logo.svg";

const address = [
  {
    Icon: LocationOnOutlinedIcon,
    address1: "Zeero, United States",
    address2: "#101 Hudson Street, Jersey City,",
    address3: "NJ-07302, United States.",
  },
  {
    Icon: LocationOnOutlinedIcon,
    address1: "Zeero, India",
    address2: "Bengaluru, Karnataka, India.",
    address3: "",
  },
];

const socialMedia = [
  {
    Icon: LinkedInIcon,
    link: "https://www.linkedin.com/company/bzeero/",
  },
  // {
  //   type: "img",
  //   Icon: TwitterIcon,
  //   link: "#",
  // },
  {
    Icon: InstagramIcon,
    link: "https://www.instagram.com/bzeero_com",
  },
  // {
  //   Icon: FacebookIcon,
  //   link: "#",
  // },
];

const emailAddress = "info@bzeero.com";
const phoneNumber = "+1 (201) 484-7402";

export const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className="footer-main">
      <div className="footerContent">
        <div className="first">
          <div
            className="logo-wdp"
            style={{ color: "#000" }}
            onClick={() => navigate("/")}
          >
            <img src={ZeeroFullLogo} alt="" />
          </div>
        </div>
        <div className="second">
          {address.map((item, index) => (
            <div key={index} className="each-item">
              <div className="location-icon">
                <item.Icon />
              </div>
              <div>
                <div className="main-address">{item.address1}</div>
                <div>{item.address2}</div>
                <div>{item.address3}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="third">
          <div className="contact">
            <div className="details-item">
              <a href={`mailto:${emailAddress}`}>
                <div className="email-icon">
                  <EmailOutlinedIcon />
                </div>
                <div>{emailAddress}</div>
              </a>
            </div>
            <div className="details-item">
              <a href={`tel:${phoneNumber}`}>
                <div className="email-icon">
                  <LocalPhoneOutlinedIcon />
                </div>
                <div>{phoneNumber}</div>
              </a>
            </div>
          </div>

          <div className="social">
            {socialMedia.map((item, index) => (
              <div key={index}>
                <a href={item.link} target="_blank">
                  {item?.type === "img" ? (
                    <img src={item?.Icon} alt="" />
                  ) : (
                    <item.Icon />
                  )}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="copyright">
        <div>Copyright Zeero. All Rights Reserved</div>
        <div>Designed by bzeero.com</div>
      </div>
    </div>
  );
};
