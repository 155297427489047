import axios from "axios";
export const afterLogin = async () => {
  try {
    const userProgress = await getUserProgress();
    // console.log("userProgress==>", userProgress);
    if (userProgress?.progress) {
      redirectAfterLogin(userProgress.progress);
    }
  } catch (err) {
    console.log("userProgress error==>", err);
  }
};

export const getUserProgress = async () => {
  try {
    const endpoint = "/candidate/getprogress";
    // const email = sessionStorage.getItem("email");
    const email = localStorage.getItem("email");
    const data = {
      id: email,
    };
    //axios post call
    const res = await axios
      .post(endpoint, data)
      .then((response) => response.data);
    return res;
  } catch (err) {
    console.log("getUserProgress error==>", err);
    return Promise.reject(err);
  }
};

export const overAllUserProgress = async () => {
  try {
    const endpoint = "/candidate/getprogress/roundProgress";
    // const email = sessionStorage.getItem("email");
    const email = localStorage.getItem("email");
    const data = {
      name: email,
    };
    //axios post call
    const res = await axios
      .post(endpoint, data)
      .then((response) => response.data);
    return res;
  } catch (err) {
    console.log("handleCheckR3Progress error==>", err);
    return Promise.reject(err);
  }
};

export const getPassedData = async () => {
  // get passed data
  // compare it with assessmentPassedData
  // accordingly add continue and passed options
  try {
    const urlEndpoint = "/candidate/passedDetails";
    // const email = sessionStorage.getItem("email");
    const email = localStorage.getItem("email");

    const data = {
      name: email,
    };
    const passedData = await axios.post(urlEndpoint, data);
    return passedData?.data;
  } catch (err) {
    console.log("gettng passed data error==>", err);
  }
};

const redirectAfterLogin = (data) => {
  if (
    data.includes("Personal Details") &&
    data.includes("Professional Experience & Technical Skills")
  ) {
    // It has both "Personal Details" and "Professional Experience & Technical Skills"
    window.location.href = "/assessment/home";
  } else if (data.includes("Personal Details")) {
    // It has "Personal Details" but not "Professional Experience & Technical Skills"
    window.location.href = "/details/professional";
  } else {
    // It doesn't have either "Personal Details" or "Professional Experience & Technical Skills"
    window.location.href = "/details/personal";
  }
};
