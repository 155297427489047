export const round2Instructions = [
  {
    title: "Round 2 Instructions",
    desc: [
      {
        desc: [
          "The test consists of 7 coding problems.",
          "It is mandatory to attempt all the questions.",
          "Total time to complete the test is 75-90 mins.",
          "Once the test begins, it must be completed in one session without the option to pause and resume later.",
          "You cannot move from one screen to another while the test is going on.",
          "You cannot copy or paste anything in the answer section.",
          "We highly recommend you to code in the programming language in which you are more proficient.",
          "Please revise the code before submitting. You cannot change the code once you click <span>'Submit'</span>.",
        ],
      },
    ],
  },
  {
    title: "General Instructions",
    desc: [
      {
        subtitle: "Before you start the test",
        desc: [
          "Make sure you have a good Internet connection.",
          "Please ensure that you are using the latest version of Chrome or Safari.",
          // "Once each test round is started, it must be completed in one sitting, you cannot abort and resume later.",
        ],
      },
      {
        subtitle: "During the test",
        desc: [
          "You can’t move to the previous question once the answer is submitted.",
          "Click on <span> 'Run Code'</span> to run the test case against your code.",
          "When you click on <span>'Skip to Next Question'</span>, you will automatically move to the next question, and the code written in the editor will be automatically submitted for evaluation.",
          "Once you click on <span>'Submit Code'</span>, you will automatically move to the next question, and your code will be submitted for evaluation.",
          "If you experience any difficulties accessing or submitting the test, please record your screen and send the recording to <span>hr@wissda.com</span> for further assistance.",
        ],
      },
    ],
  },
];

export const round2InstructionsDataScience = [
  {
    title: "Round 2 Instructions",
    desc: [
      {
        desc: [
          "The test consists of 4 coding problems.",
          "It is mandatory to attempt all the questions.",
          "Total time to complete the test is 75-90 mins.",
          "Once the test begins, it must be completed in one session without the option to pause and resume later.",
          "You cannot move from one screen to another while the test is going on.",
          "You cannot copy or paste anything in the answer section.",
          "Please revise the code before submitting. You cannot change the code once you click <span>'Submit'</span>.",
        ],
      },
    ],
  },
  {
    title: "General Instructions",
    desc: [
      {
        subtitle: "Before you start the test",
        desc: [
          "Make sure you have a good Internet connection.",
          "Please ensure that you are using the latest version of Chrome or Safari.",
          // "Once each test round is started, it must be completed in one sitting, you cannot abort and resume later.",
        ],
      },
      {
        subtitle: "During the test",
        desc: [
          "You can’t move to the previous question once the answer is submitted.",
          "Click on <span> 'Run Code'</span> to run the test case against your code.",
          "When you click on <span>'Skip to Next Question'</span>, you will automatically move to the next question, and the code written in the editor will be automatically submitted for evaluation.",
          "Once you click on <span>'Submit Code'</span>, you will automatically move to the next question, and your code will be submitted for evaluation.",
          "If you experience any difficulties accessing or submitting the test, please record your screen and send the recording to <span>hr@wissda.com</span> for further assistance.",
        ],
      },
    ],
  },
];

export const betweenTestInstructions = {
  title: "Instructions",
  desc: [
    "You can’t move to the previous question once the answer is submitted.",
    `Click on "Run Code" to run the test case against your code.`,
    `When you click on "Skip to Next Question", you will automatically move to the next question and the code written on the editor will automatically be submitted for evaluation.`,
    `Once you click on "Submit Code", you will automatically move to the next question and your code will be submitted for evaluation.`,
    `If you experience any difficulties accessing or submitting the test, please record your screen and send the recording to <span>hr@wissda.com </span> for further assistance.`,
  ],
};
