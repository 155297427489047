import {
  BrowserRouter,
  Routes,
  Route,
  useRoutes,
  useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LinkedInCallback } from "react-linkedin-login-oauth2";

import { CandidateLogin } from "../Pages/CandidateLogin/CandidateLogin";
import { CandidateWelcome } from "../Pages/CandidateWelcome/CandidateWelcome";
import { Home } from "../Pages/Home/Home";
import { PersonalDetails } from "../Pages/CandidateDetails/PersonalDetails/PersonalDetails";
// import { ProfessionalDetails } from "../Pages/CandidateDetails/ProfessionalDetails/ProfessionalDetails";
import { ProfessionalDetails } from "../Pages/CandidateDetails/ProfessionalDetails/ProfessionalDetails2";
import { CustomerSupport } from "../Pages/CustomerSupport/CustomerSupport";

import { CandidateOffersActive } from "../Pages/CandidateOffers/CandidateActiveOffers";
import { CandidateIssues } from "../Pages/CandidateIssues/CandidateIssues";
import { AssessmentHome } from "../Pages/AssessmentPages/AssessmentHome/AssessmentHome";
// import { AssessmentHome } from "../Pages/AssessmentPages/AssessmentHome/AssessmentHome";
import { AssessmentRound1 } from "../Pages/AssessmentPages/AssessmentRound1/AssessmentRound1";
import { AssessmentRound2 } from "../Pages/AssessmentPages/AssessmentRound2/AssessmentRound2";
import { ForgotPasswprd } from "../Pages/ForgotPasswprd/ForgotPasswprd";
import { RegisterCandidate } from "../Pages/RegisterCandidate/RegisterCandidate";
import { AssessmentRound3 } from "../Pages/AssessmentPages/AssessmentRound3/AssessmentRound3";
import { useEffect, useState } from "react";
import axios from "axios";
import { AssessmentRound4 } from "../Pages/AssessmentPages/AssessmentRound4/AssessmentRound4";
import { AssessmentRound5 } from "../Pages/AssessmentPages/AssessmentRound5/AssessmentRound5";
import { CandidateOffersExpired } from "../Pages/CandidateOffers/CandidateExpiredOffers";
import { Home_Developer } from "../Pages/Home_Developer/Home_Developer";
import { Home_Client } from "../Pages/Home_Client/Home_Client";
import { Contact_Page } from "../Pages/Contact_Page/Contact_Page";
import { Contact_Client } from "../Pages/Contact_Client/Contact_Client";
import { AlwaysForwardToRound2 } from "../Pages/AssessmentPages/AssessmentRound2/AlwaysForwardToRound2";
// import { GoogleTagManagerScript } from "../Components/GoogleAnalytics/GoogleAnalytics";
// import { PrivacyPolicy } from "../Pages/PrivacyPage/privacy_1";

export const AllRoutes = () => {
  return (
    <BrowserRouter>
      {/* <GoogleTagManagerScript /> */}
      <Routes>
        {/*<Route exact path="/" element={<Home />}></Route> */}
        {/* <Route exact path="/privacy-policy" element={<PrivacyPolicy />}></Route> */}

        {/* <Route exact path="/developer" element={<Home_Developer />}></Route> */}
        {/* <Route exact path="/client" element={<Home_Client />}></Route> */}
        {/* <Route exact path="/contact/all" element={<Contact_Page />}></Route> */}
        {/* <Route
          exact
          path="/contact/client"
          element={<Contact_Client />}
        ></Route> */}
        <Route exact path="/login" element={<CandidateLogin />}></Route>
        <Route exact path="/sign-in" element={<CandidateLogin />}></Route>
        <Route exact path="/linkedin" element={<LinkedInCallback />} />
        <Route exact path="/forgot-password" element={<ForgotPasswprd />} />
        <Route exact path="/register" element={<RegisterCandidate />} />
        <Route exact path="/sign-up" element={<RegisterCandidate />} />

        <Route path="/*" element={<SubRoutes />}></Route>
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
};

const SubRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Replace with your actual API call
    const checkAuthentication = async () => {
      try {
        const data = {
          email: localStorage.getItem("email"),
        };
        const response = await axios.post("/validate/auth", data);
        const data1 = response?.data;
        if (data1?.isAuthenticated) setIsAuthenticated(data1.isAuthenticated);
        // Assuming the API returns an object with an isAuthenticated field
        else setIsAuthenticated(false);
      } catch (error) {
        console.error("Error checking authentication:==>", error);
        setIsAuthenticated(false);
      }
    };

    checkAuthentication();
  }, []);

  const renderSubRoutes = useRoutes([
    { path: "/welcome", element: <CandidateWelcome /> },
    { path: "/offers/active", element: <CandidateOffersActive /> },
    { path: "/offers/expired", element: <CandidateOffersExpired /> },
    { path: "/issues-raised", element: <CandidateIssues /> },
    { path: "/details/personal", element: <PersonalDetails /> },
    { path: "/details/professional", element: <ProfessionalDetails /> },
    { path: "/support-and-faqs", element: <CustomerSupport /> },
    { path: "/assessment/home", element: <AssessmentHome /> },
    { path: "/assessment/round1", element: <AssessmentRound1 /> },
    { path: "/assessment/gotoRound2", element: <AlwaysForwardToRound2 /> },
    { path: "/assessment/round2", element: <AssessmentRound2 /> },
    { path: "/assessment/round3", element: <AssessmentRound3 /> },
    // { path: "/assessment/round4", element: <AssessmentRound4 /> },
    // { path: "/assessment/round5", element: <AssessmentRound5 /> },
  ]);

  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Or some other loading indicator
  }

  if (!isAuthenticated) {
    // Redirect to login
    navigate("/login");
    return null;
  }

  return renderSubRoutes; //
};
