import { useEffect, useRef, useState } from "react";
import { CustomerSupportData } from "../../mockData/CustomerSupport";
import "./CustomerSupport.scss";
import { CandidateHeader } from "../../Components/CandidateHeader/CandidateHeader";
import arrowDown from "../../Assets/SVG/dropDownArrow.svg";
import axios from "axios";
import notepad from "../../Assets/SVG/notepad.svg";
import notepadSingle from "../../Assets/SVG/notepadOne.svg";
import { Link } from "react-router-dom";
import { RaiseTicket } from "../../Components/RaiseTicket/RaiseTicket";

const mokeQues = [{}];

export const CustomerSupport = () => {
  const [selectedOption, setSelectedOption] = useState(0);
  const [category, setCategory] = useState(
    CustomerSupportData?.Subtitles?.[0]?.heading || "General"
  );
  //   const [allFaqs, setAllFaqs] = useState([]);
  const [allFaqs, setAllFaqs] = useState(mokeQues);

  const [active, setActive] = useState(0);
  const handleActive = (index) => {
    if (active === index) setActive(null);
    else setActive(index);
  };

  const handleChangeSelectedOption = (index) => {
    setSelectedOption(index);
    if (index === 0) {
      setCategory(CustomerSupportData?.Subtitles?.[0]?.heading || "General");
    } else {
      setCategory(CustomerSupportData?.Subtitles?.[index]?.options?.[0]);
    }
    setActive(-1);
  };

  const handleGetRelatedFaqs = async () => {
    try {
      const endpoint = category?.value ? category.value : category;
      const apiUrl = "/api/faqs/" + endpoint;
      const res = await axios.get(apiUrl).then((res) => res.data);
      if (res && res?.length > 0) {
        console.log(res?.length);
        setAllFaqs([...res]);
        setActive(0);
      }
    } catch (err) {
      console.log("handleGetRelatedFaqs error ==>", err);
    }
  };

  useEffect(() => {
    handleGetRelatedFaqs();
  }, [category]);

  return (
    <>
      <CandidateHeader />
      <div className="CustomerSupportWrapper">
        <div className="title">{CustomerSupportData?.title}</div>
        <div className="Subheading">
          {CustomerSupportData?.Subtitles?.map((item, index) => (
            <div
              key={index}
              onClick={() => handleChangeSelectedOption(index)}
              className={selectedOption === index ? "active" : ""}
            >
              {item?.heading}
            </div>
          ))}
        </div>
        {CustomerSupportData?.Subtitles?.[selectedOption]?.options && (
          <div className="main-dropdown-container">
            <DropDownBox
              options={
                CustomerSupportData?.Subtitles?.[selectedOption]?.options
              }
              value={category}
              handleChange={setCategory}
            />
          </div>
        )}
        <div className="qna">
          <QuesAndAns
            data={allFaqs}
            active={active}
            handleActive={handleActive}
          />
          {/* {allFaqs.length > 0 && <RaiseTicketBox />} */}
        </div>
      </div>
    </>
  );
};

const QuesAndAns = ({ data, active, handleActive }) => {
  return (
    <div className="faq-data">
      {data.map((item, index) => (
        <div key={index} className="ques-ans">
          <div
            className="ques"
            onClick={() => handleActive(index)}
            style={{ background: active === index ? "#E3E3E3" : "" }}
          >
            <div>{item?.question}</div>
            <div>
              {
                <img
                  src={
                    active === index
                      ? "./icons/minus-sign.svg"
                      : "./icons/plus-sign.svg"
                  }
                  alt=""
                />
              }
            </div>
          </div>
          {active === index && <div className="ans">{item?.answer}</div>}
        </div>
      ))}
    </div>
  );
};

const DropDownBox = ({ placeholder, options, value, handleChange }) => {
  const [viewOptions, setViewOptions] = useState(false);
  const componentRef = useRef(null);

  const dropDownChange = (item) => {
    handleChange(item);
    setViewOptions(false);
  };

  useEffect(() => {
    // Check if the element exists
    if (componentRef.current) {
      if (viewOptions) {
        // If viewOptions is true, add the 'clicked' class
        componentRef.current.classList.add("clickedItem");
      } else {
        // If viewOptions is false, remove the 'clicked' class
        componentRef.current.classList.remove("clickedItem");
      }
    }
  }, [viewOptions]);

  return (
    <div className="dropDown-wrapper" ref={componentRef}>
      <div className="placeholder" onClick={() => setViewOptions(!viewOptions)}>
        <div className={value === "" ? "" : "value"}>
          {value === "" || value === null ? placeholder : value}
        </div>
        <div style={{ paddingTop: viewOptions ? "5px" : "8px" }}>
          <img
            src={arrowDown}
            alt=""
            style={{ rotate: viewOptions ? "180deg" : "0deg" }}
          />
        </div>
      </div>
      {viewOptions && (
        <div className="options-wrapper">
          {options?.map((item, index) => (
            <div key={index} onClick={() => dropDownChange(item)}>
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const RaiseTicketBox = () => {
  const [issueCount, setIssueCount] = useState(0);
  const [viewTicketBox, setViewTicketBox] = useState(false);

  return (
    <>
      <div className="raise-ticket-container">
        {issueCount > 0 ? (
          <div className="raise-ticket-wrapper">
            <div className="raise-ticket-icon">
              <img src={notepadSingle} alt="" />
            </div>
            <div className="issue-title">{issueCount} Issues Raised</div>
            <div>
              <button onClick={() => setViewTicketBox(true)}>
                Raise a New Ticket
              </button>
            </div>
            <div className="toIssues">
              <Link to="/issues">Go to Issue Tracker</Link>
            </div>
          </div>
        ) : (
          <div className="raise-ticket-wrapper">
            <div className="raise-ticket-icon">
              <img src={notepad} alt="" />
            </div>
            <div className="issue-title">No Issues Raised</div>
            <div className="issue-title-2">
              Here you can raise any issues that you have faced on the portal.
            </div>
            <div>
              <button onClick={() => setViewTicketBox(true)}>
                Raise a New Ticket
              </button>
            </div>
          </div>
        )}
      </div>
      {viewTicketBox && <RaiseTicket setViewRaiseTicket={setViewTicketBox} />}
    </>
  );
};
